/* button default */
.btn { @apply px-2 rounded-md shadow-md border border-transparent leading-5 font-medium rounded-md transition ease-in-out duration-150; }
/* button type */
.btn-width { @apply inline-flex items-center; }
.btn-width-full { @apply w-full flex justify-center; }
/* button icons */
.btn-icon-left { @apply ml-1 mr-2 h-5 w-5; }
.btn-icon-right { @apply mr-1 ml-2 h-5 w-5; }
/* button primary */
.btn-primary { @apply text-white bg-blue-600 border border-blue-700; }
.btn-primary:hover { @apply bg-blue-500; }
.btn-primary:focus { @apply border-blue-700; }
.btn-primary:active { @apply bg-blue-700; }
.btn-primary-outline { @apply text-blue-700 bg-blue-100 border border-blue-500; }
.btn-primary-outline:hover { @apply bg-blue-200 border-blue-600; }
.btn-primary-outline:focus { @apply border-blue-600 bg-blue-300; }
.btn-primary-outline:active { @apply bg-blue-300; }
/* button secondary */
.btn-secondary { @apply text-white bg-gray-600 border border-gray-700; }
.btn-secondary:hover { @apply bg-gray-500; }
.btn-secondary:focus { @apply border-gray-700; }
.btn-secondary:active { @apply bg-gray-700; }
.btn-secondary-outline { @apply text-gray-700 bg-gray-100 border border-gray-400; }
.btn-secondary-outline:hover { @apply bg-gray-200 border-gray-600; }
.btn-secondary-outline:focus { @apply border-gray-600 bg-gray-300; }
.btn-secondary-outline:active { @apply bg-gray-300; }
/* button success */
.btn-success { @apply text-white bg-green-600 border border-green-700; }
.btn-success:hover { @apply bg-green-500; }
.btn-success:focus { @apply border-green-700; }
.btn-success:active { @apply bg-green-700; }
.btn-success-outline { @apply text-green-700 bg-green-100 border border-green-500; }
.btn-success-outline:hover { @apply bg-green-200 border-green-600; }
.btn-success-outline:focus { @apply border-green-600 bg-green-300; }
.btn-success-outline:active { @apply bg-green-300; }
/* button info */
.btn-info { @apply text-white bg-indigo-600 border border-indigo-700; }
.btn-info:hover { @apply bg-indigo-500; }
.btn-info:focus { @apply border-indigo-700; }
.btn-info:active { @apply bg-indigo-700; }
.btn-info-outline { @apply text-indigo-700 bg-indigo-100 border border-indigo-500; }
.btn-info-outline:hover { @apply bg-indigo-200 border-indigo-600; }
.btn-info-outline:focus { @apply border-indigo-600 bg-indigo-300; }
.btn-info-outline:active { @apply bg-indigo-300; }
/* button warning */
.btn-warning { @apply text-white bg-yellow-600 border border-yellow-700; }
.btn-warning:hover { @apply bg-yellow-500; }
.btn-warning:focus { @apply border-yellow-700; }
.btn-warning:active { @apply bg-yellow-700; }
.btn-warning-outline { @apply text-yellow-700 bg-yellow-100 border border-yellow-500; }
.btn-warning-outline:hover { @apply bg-yellow-200 border-yellow-600; }
.btn-warning-outline:focus { @apply border-yellow-600 bg-yellow-300; }
.btn-warning-outline:active { @apply bg-yellow-300; }
/* button danger */
.btn-danger { @apply text-white bg-red-600 border border-red-700; }
.btn-danger:hover { @apply bg-red-500; }
.btn-danger:focus { @apply border-red-700; }
.btn-danger:active { @apply bg-red-700; }
.btn-danger-outline { @apply text-red-700 bg-red-100 border border-red-500; }
.btn-danger-outline:hover { @apply bg-red-200 border-red-600; }
.btn-danger-outline:focus { @apply border-red-600 bg-red-300; }
.btn-danger-outline:active { @apply bg-red-300; }
/* button link */
.btn-link { @apply text-gray-600 shadow-none border-0; }
.btn-link:hover { @apply text-gray-500; }
.btn-link-outline { @apply text-gray-700; }
.btn-link-outline:hover { @apply text-gray-200; }
.btn-link-outline:focus { @apply text-gray-300; }
.btn-link-outline:active { @apply text-gray-300; }
/* button sizes */
.btn-xs { @apply text-xs h-6 px-2 py-0; }
.btn-sm { @apply text-sm h-8 px-2 py-1; }
.btn-md { @apply text-base h-10 px-3 py-2; }
.btn-lg { @apply text-lg h-12 px-3 py-2; }
.btn-xl { @apply text-xl h-12 px-4 py-2; }
