.badge { @apply inline-flex items-center font-medium border uppercase shadow; }
/* rounded badge */
.badge-rounded { @apply rounded-md; }
.badge-rounded-full { @apply rounded-full; }
/* badge size */
.badge-sm { @apply text-xs px-2 mx-2 my-1; }
.badge-md { @apply text-sm px-3 py-1 mx-2 my-1; }
/* primary badge */
.badge-primary { @apply bg-blue-200 text-blue-800 border-blue-300; }
.badge-primary-outline { @apply bg-blue-100 text-blue-800 border-blue-300; }
/* secondary badge */
.badge-secondary { @apply bg-gray-200 text-gray-800 border-gray-300; }
.badge-secondary-outline { @apply bg-gray-100 text-gray-800 border-gray-300; }
/* success badge */
.badge-success { @apply bg-green-200 text-green-800 border-green-300; }
.badge-success-outline { @apply bg-green-100 text-green-800 border-green-300; }
/* info badge */
.badge-info { @apply bg-indigo-200 text-indigo-800 border-indigo-300; }
.badge-info-outline { @apply bg-indigo-100 text-indigo-800 border-indigo-300; }
/* warning badge */
.badge-warning { @apply bg-yellow-200 text-yellow-800 border-yellow-300; }
.badge-warning-outline { @apply bg-yellow-100 text-yellow-800 border-yellow-300; }
/* danger badge */
.badge-danger { @apply bg-red-200 text-red-800 border-red-300; }
.badge-danger-outline { @apply bg-red-100 text-red-800 border-red-300; }
